import React, { useEffect, useState } from 'react';
import { TonConnectButton, useTonWallet } from '@tonconnect/ui-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import '../styles/main.css';

const Header = ({ onBuyITKClick }) => {
  const wallet = useTonWallet();
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Inicializando navigate aqui

  useEffect(() => {
    const fetchJettonBalance = async () => {
      if (wallet && wallet.account) {
        const walletAddress = encodeURIComponent(wallet.account.address);
        const jettonId = '0:dc8c2017075af5dda0e5a42762b669782064ec27785962f625d144baa605af98'; // ID do Jetton ITK
        try {
          const response = await axios.get(`https://tonapi.io/v2/accounts/${walletAddress}/jettons/${jettonId}`, {
            params: {
              currencies: 'usd,rub,brl,ton',
            },
            headers: {
              'accept': 'application/json'
            }
          });
    
          if (response.status === 200) {
            const balanceInNanotons = response.data.balance;
            const decimals = response.data.jetton.decimals;
            const balanceInITK = balanceInNanotons / Math.pow(10, decimals); // Converter de nanotons para ITK
            setBalance(balanceInITK);
          } else {
            console.error('Erro ao obter o saldo:', response.data.error);
          }
        } catch (error) {
          console.error('Erro ao buscar saldo:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    
    fetchJettonBalance();
  }, [wallet]);

  // Função para redirecionar para a página /buy
  const handleBuyITKClick = () => {
    if (wallet && wallet.account) {
      const walletAddress = encodeURIComponent(wallet.account.address);
      navigate(`/buy?walletAddress=${walletAddress}`); // Passa o endereço da carteira na URL
    } else {
      alert('Wallet is not connected');
    }
  };

  return (
    <header className="header">
      <div className="top-left">
        {loading ? (
          <span>Loading...</span>
        ) : (
          <>
            <span><b>  {balance} ITK</b></span>
            {balance === 0 && (
              <button className="buy-itk-button" onClick={handleBuyITKClick}>
                <img src="https://itrackers.cloud/imgs/itrackers.svg" className="itk" alt="ITK logo" />
                Buy ITK
              </button>
            )}
          </>
        )}
      </div>
      <div className="connect-wallet1">
        <TonConnectButton />
      </div>
    </header>
  );
};

export default Header;
