// src/components/SearchOptions.js
import React, { useState } from 'react';
import '../styles/main.css';
import '../styles/teste.css';

const SearchOptions = ({ selectedOption, onOptionChange }) => {
  return (
    <div className="search-options-wrapper">
      <div className="option-container">
        <input
          type="radio"
          name="searchtype"
          value="email"
          id="email"
          className="radio-label-button"
          checked={selectedOption === 'email'}
          onChange={onOptionChange}
        />
        <label htmlFor="email" className="radio-button-width"><i class="fa fa-envelope"></i> Email</label>

        <input
          type="radio"
          name="searchtype"
          value="username"
          id="username"
          className="radio-label-button"
          checked={selectedOption === 'username'}
          onChange={onOptionChange}
        />
        <label htmlFor="username" className="radio-button-width"><i class="fas fa-user"></i> Username</label>

        <input
          type="radio"
          name="searchtype"
          value="lastip"
          id="lastip"
          className="radio-label-button"
          checked={selectedOption === 'lastip'}
          onChange={onOptionChange}
        />
        <label htmlFor="lastip" className="radio-button-width"><i class="fa-solid fa-location-dot"></i> IP Address</label>

        <input
          type="radio"
          name="searchtype"
          value="password"
          id="password"
          className="radio-label-button"
          checked={selectedOption === 'password'}
          onChange={onOptionChange}
        />
        <label htmlFor="password" className="radio-button-width"><i class="fa-solid fa-lock"></i> Password</label>

        <input
          type="radio"
          name="searchtype"
          value="name"
          id="name"
          className="radio-label-button"
          checked={selectedOption === 'name'}
          onChange={onOptionChange}
        />
        <label htmlFor="name" className="radio-button-width"><i class="fa-solid fa-address-card"></i> Full Name</label>

        <input
          type="radio"
          name="searchtype"
          value="hash"
          id="hash"
          className="radio-label-button"
          checked={selectedOption === 'hash'}
          onChange={onOptionChange}
        />
        <label htmlFor="hash" className="radio-button-width"><i class="fa-solid fa-hashtag"></i> Hash</label>

        <input
          type="radio"
          name="searchtype"
          value="domain"
          id="domain"
          className="radio-label-button"
          checked={selectedOption === 'domain'}
          onChange={onOptionChange}
        />
        <label htmlFor="domain" className="radio-button-width"><i class="fa-solid fa-globe"></i> Domain</label>

        <input
          type="radio"
          name="searchtype"
          value="phone"
          id="phone"
          className="radio-label-button"
          checked={selectedOption === 'phone'}
          onChange={onOptionChange}
        />
        <label htmlFor="phone" className="radio-button-width"><i class="fa-solid fa-phone"></i> Phone</label>
      </div>
    </div>
  );
};

export default SearchOptions;
