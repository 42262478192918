import React, { useState, useEffect } from 'react';
import { useTonWallet, useTonConnectUI } from '@tonconnect/ui-react';
import '../styles/purchaseITK.css';
import ParticlesBackground from '../components/ParticlesBackground';

const PurchaseITK = ({ onBack }) => {
  const wallet = useTonWallet(); // Acessa a wallet conectada
  const [tonConnectUI] = useTonConnectUI(); // Usa o TonConnectUI para enviar transações
  const [itkAmount, setItkAmount] = useState(0);
  const [tonAmount, setTonAmount] = useState(0);
  const itkPriceInTon = 0.001; // Preço fixo de 1 ITK em TON

  const [addToPlatform, setAddToPlatform] = useState(false); // Estado do checkbox "Adicionar ITK na plataforma"
  const [addToWallet, setAddToWallet] = useState(false); // Estado do checkbox "Adicionar ITK na Wallet"
  const [telegramUserId, setTelegramUserId] = useState(''); // UserID do Telegram (você pode obter isso de alguma lógica já existente no seu projeto)

  useEffect(() => {
    setTonAmount(itkAmount * itkPriceInTon);
  }, [itkAmount]);

  const handlePurchase = async () => {
    if (!wallet) {
      alert('Wallet is not connected. Please connect your wallet.');
      return;
    }

    if (!addToPlatform && !addToWallet) {
      alert('Please select where to add ITK: Platform or Wallet.');
      return;
    }

    try {
      // Dados da transação
      const transaction = {
        messages: [
          {
            address: process.env.REACT_APP_ADMIN_WALLET_ADDRESS, // Endereço do administrador
            amount: (tonAmount * 1e9).toString() // Quantidade de TON a enviar em nanotons
          }
        ]
      };

      // Envie a transação via TonConnect
      await tonConnectUI.sendTransaction(transaction);

      // Enviar os detalhes da compra para o bot do Telegram
      const choice = addToPlatform ? 'Plataforma' : 'Wallet';
      await sendPurchaseOrderToTelegram({
        telegramUserId,
        walletAddress: wallet.account.address,
        itkAmount,
        tonAmount,
        choice,
      });

      alert(`You have successfully sent ${tonAmount} TON for ${itkAmount} ITK.`);
    } catch (error) {
      console.error('Error processing transaction:', error);
      alert('An error occurred while processing the transaction.');
    }
  };

  const sendPurchaseOrderToTelegram = async ({ telegramUserId, walletAddress, itkAmount, tonAmount, choice }) => {
    try {
      const botToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN; // Token do bot Telegram no .env
      const chatId = telegramUserId; // ID do usuário no Telegram
      const message = `
        New ITK Purchase Order:
        - Telegram User ID: ${telegramUserId}
        - Wallet Address: ${walletAddress}
        - ITK Amount: ${itkAmount}
        - TON Amount: ${tonAmount.toFixed(6)} TON
        - Add to: ${choice}
      `;

      // URL da API do Telegram para enviar mensagens
      const telegramApiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

      // Enviar requisição para o Telegram
      await fetch(telegramApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message,
        }),
      });

      console.log('Order sent to Telegram successfully');
    } catch (error) {
      console.error('Failed to send message to Telegram:', error);
    }
  };

  return (
    <center>
      <ParticlesBackground />
      <div className="purchase-container">
        <h2>Buy ITK</h2>
        <div className="buy">
          <label>ITK Amount:</label>
          <input
            type="number"
            min="0"
            step="1"
            value={itkAmount}
            onChange={(e) => setItkAmount(e.target.value)}
            className="input-amount"
            placeholder="0"
          />
        </div>
        <p>You will pay: {tonAmount.toFixed(6)} TON</p>
    <div className='optionWallet'>
        <div>
          <label>
            <input
              type="radio"
              name="purchaseOption"
              checked={addToPlatform}
              onChange={() => {
                setAddToPlatform(true);
                setAddToWallet(false);
              }}
            />
            Add ITK to Platform
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              name="purchaseOption"
              checked={addToWallet}
              onChange={() => {
                setAddToWallet(true);
                setAddToPlatform(false);
              }}
            />
            Add ITK to Wallet
          </label>
        </div>
        </div>
        <button onClick={handlePurchase} className="btn-primary">
          <img src="https://itrackers.cloud/imgs/itrackers.svg" className="itk" alt="ITK logo" />
          Buy
        </button>
      </div>
    </center>
  );
};

export default PurchaseITK;
