// src/components/SearchOptions.js
import React, { useState } from 'react';
import '../styles/main.css';
import '../styles/teste.css';

const SearchOptions = ({ selectedOption, onOptionChange }) => {
  return (
    <div className="search-options-wrapper">
      <div className="option-container">
        <input
          type="radio"
          name="searchtype"
          value="email"
          id="email"
          className="radio-label-button"
          checked={selectedOption === 'email'}
          onChange={onOptionChange}
        />
        <label htmlFor="email" className="radio-button-width">Leaks</label>

        <input
          type="radio"
          name="searchtype"
          value="username"
          id="username"
          className="radio-label-button"
          checked={selectedOption === 'username'}
          onChange={onOptionChange}
        />
        <label htmlFor="username" className="radio-button-width">Data Query</label>

      </div>
    </div>
  );
};

export default SearchOptions;
