// src/components/Footer.js
import React from 'react';
import '../styles/main.css';

const Footer = ({ tonValue, itkValue }) => {
  return (
    <center>
      <footer className="footer">
        <span className="pricenow"><b>PRICE NOW:</b></span><br />
       <div><span className="pricenow"><b>TON: ${tonValue}</b> - </span>
        <span className="pricenow"><b>ITK: $0.001</b></span></div> 
        <div className="container">
          <p><b>&copy; 2024 iTrackers. All rights reserved.</b></p>
          <p className="foot-ton">Powered by TON Blockchain</p>
        </div>
      </footer>
    </center>
  );
};

export default Footer;
