// src/pages/MainContent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/main.css';
import '../styles/modal.css';
import '../styles/FormattedResults.css';
import '../styles/teste.css';
import logo from '../assets/logo.png';
import ParticlesBackground from '../components/ParticlesBackground';
import SearchOptions from '../components/SearchOptions';
import SnusbaseOptions from '../components/SnusbaseOptions';
import ItrackersOptions from '../components/ItrackersOptions';
import FormattedResults from '../components/FormattedResults';
import { useTonWallet } from '@tonconnect/ui-react';


const MainContent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState('email');
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const wallet = useTonWallet();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [activeOption, setActiveOption] = useState(''); // New state for Leaks or Data Query
  const apiEndpoints = {
    cpf: `https://api.itrackers.cloud/api/cpf?cpf=${searchTerm}`,
    telefone: `https://api.itrackers.cloud/api/telrec?telefone=${searchTerm}`,
    cpf1: `https://api.itrackers.cloud/api/cpf1?cpf=${searchTerm}`,
    cpf2: `https://api.itrackers.cloud/api/cpf2?cpf=${searchTerm}`,
    renda: `https://api.itrackers.cloud/api/renda?cpf=${searchTerm}`,
    spc: `https://api.itrackers.cloud/api/spc?cpf=${searchTerm}` ,
    spc1: `https://api.itrackers.cloud/api/spc1?cpf=${searchTerm}` ,
    cadsus: `https://api.itrackers.cloud/api/cadsus?cpf=${searchTerm}`,
    receitacpf: `https://api.itrackers.cloud/api/receitacpf?cpf=${searchTerm}`,
    nb: `https://api.itrackers.cloud/api/nb?cpf=${searchTerm}`,
    cpftel: `https://api.itrackers.cloud/api/cpftel?cpf=${searchTerm}`,
    cpfmail: `https://api.itrackers.cloud/api/cpfmail?cpf=${searchTerm}`,
    score: `https://api.itrackers.cloud/api/score?cpf=${searchTerm}`,
    parente: `https://api.itrackers.cloud/api/parente?cpf=${searchTerm}`,
    cepfull: `https://api.itrackers.cloud/api/cepfull?cep=${searchTerm}`,
    spcnome: `https://api.itrackers.cloud/api/spcnome?nome=${searchTerm}`,
    nome: `https://api.itrackers.cloud/api/nome?nome=${searchTerm}`,
    nomes: `https://api.itrackers.cloud/api/nomes?nome=${searchTerm}`,
    spcemail: `https://api.itrackers.cloud/api/spcemail?email=${searchTerm}`,
    email1: `https://api.itrackers.cloud/api/email1?email=${searchTerm}`,
    detran: `https://api.itrackers.cloud/api/detran?placa=${searchTerm}`,
    data: `https://api.itrackers.cloud/api/data?data=${searchTerm}`,
    spctel: `https://api.itrackers.cloud/api/spctel?tel=${searchTerm}`,
    telefonix: `https://api.itrackers.cloud/api/telefonix?tel=${searchTerm}`,
    tel: `https://api.itrackers.cloud/api/tel?telefone=${searchTerm}`,
    rg: `https://api.itrackers.cloud/api/rg?rg=${searchTerm}`,
    titulo: `https://api.itrackers.cloud/api/titulo?titulo=${searchTerm}`,
    fotorj: `https://api.itrackers.cloud/api/foto?cpf=${searchTerm}`,
    cbo: `https://api.itrackers.cloud/api/cbo?cbo=${searchTerm}`,
    cons: `https://api.itrackers.cloud/api/cons?cpf=${searchTerm}`,



  };

  useEffect(() => {
    // Atualiza o estado de conexão da wallet
    setIsWalletConnected(!!wallet && !!wallet.account);
  }, [wallet]);


  const handleSearch = async () => {
    setLoading(true);
    try {
      const url = apiEndpoints[selectedOption];
      if (url) {
        const response = await axios.get(url);
        setSearchResults({ [`${selectedOption.toUpperCase()} API`]: [response.data] });
      } else {
        // Lógica para API Snusbase
        const response = await axios.post('https://api.snusbase.com/data/search', {
          terms: [searchTerm],
          types: [selectedOption],
          wildcard: false
        }, {
          headers: {
            'Auth': process.env.REACT_APP_SNUSBASE_API_KEY,
            'Content-Type': 'application/json'
          }
        });
        setSearchResults(response.data);
      }
      setShowModal(true);
    } catch (error) {
      console.error('Error searching:', error);
    } finally {
      setLoading(false);
    }
  };
  


  const handleLeaks = () => {
    setActiveOption('leaks'); // Set the active option to 'leaks'
  };

  const handleDataQuery = () => {
    setActiveOption('data-query'); // Set the active option to 'data-query'
  };
  

  const handleCloseModal = () => setShowModal(false);

  // Verifica se o botão deve estar desabilitado
  const isButtonDisabled = loading || searchTerm.trim() === '';

  return (
    <div className="main-content-wrapper">
      <ParticlesBackground />

      <div className="main-content">
        <img src={logo} alt="iTrackers Logo" className="logo" />

        {isWalletConnected ? (
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              className="search-button"
              onClick={handleSearch}
              disabled={isButtonDisabled}
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-search"></i>}
            </button>
          </div>


          
        ) : (
          <div className="wallet-message">
            <p>Please connect your wallet to continue.</p>
          </div>
        )}

  {/* {isWalletConnected && (
          <SearchOptions
            selectedOption={selectedOption}
            onOptionChange={(e) => setSelectedOption(e.target.value)}
          />
        )} */}      

        {isWalletConnected &&(

<div className="btnOptions">
<button className="bt-itk-button" onClick={handleLeaks}>
    <img src="https://itrackers.cloud/imgs/itrackers.svg" className="itk" alt="Leaks" />
    Leaks
  </button>
  <button className="bt-itk-button" onClick={handleDataQuery}>
    <img src="https://itrackers.cloud/imgs/itrackers.svg" className="itk" alt="Data Query" />
    Data Query BR
  </button>


</div>
        )}




{activeOption === 'leaks' && (
          <SnusbaseOptions
            selectedOption={selectedOption}
            onOptionChange={(e) => setSelectedOption(e.target.value)}
          />
        )}

        {activeOption === 'data-query' && isWalletConnected && (
          <ItrackersOptions
            selectedOption={selectedOption}
            onOptionChange={(e) => setSelectedOption(e.target.value)}
          />
        )}

{showModal && searchResults && (
  <div className="modal-overlay" onClick={handleCloseModal}>
    <button className="modal-close" onClick={handleCloseModal}>X</button>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <h2>Search Results</h2>
      {/* Verifica a activeOption para escolher qual FormattedResults exibir */}
      {activeOption === 'leaks' ? (
        <FormattedResults results={searchResults.results} />
      ) : (
        <FormattedResults results={searchResults} />
        
      )}
      
      <button
        className="copy-button"
        onClick={() => {
          navigator.clipboard.writeText(JSON.stringify(searchResults, null, 2));
          alert('Results copied to clipboard!');
        }}
      >
        Copy All
      </button>
    </div>
  </div>
)}




      </div>
    </div>
  );
};

export default MainContent;
