import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader';
import Header from './components/Header';
import axios from 'axios';
import PurchaseITK from './components/PurchaseITK';
import MainContent from './pages/MainContent';
import Footer from './components/Footer';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Wallet from './pages/Wallet';



const App = () => {
  const [loading, setLoading] = useState(true);
  const [tonValue, setTonValue] = useState(0);
  const [itkValue, setItkValue] = useState(0);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000); // Simulação de carregamento
  }, []);

  useEffect(() => {
    // Fetch valores de TON e ITK
    const fetchData = async () => {
      try {
        const tonResponse = await axios.get('https://tonapi.io/v2/rates?tokens=ton&currencies=usd');
        const itkResponse = await axios.get('https://tonapi.io/v2/rates?tokens=itk&currencies=usd');
        
        setTonValue(tonResponse.data.rates.TON.prices.USD.toFixed(4));
        setItkValue(itkResponse.data.rates.ITK.prices.USD.toFixed(4));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const manifestUrl = "https://panel.itrackers.cloud/tonconnect-manifest.json";

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <Router>
        <div className="app">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Header />
              <Routes>
                <Route path="/" element={<MainContent />} />
                <Route path="/buy" element={<PurchaseITK />} />
                <Route path="/wallet" element={<Wallet />} />

              </Routes>
              <Footer tonValue={tonValue} itkValue={itkValue} />
            </>
          )}
        </div>
      </Router>
    </TonConnectUIProvider>
  );
};

export default App;
