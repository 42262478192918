// src/components/SearchOptions.js
import React, { useState } from 'react';
import '../styles/main.css';
import '../styles/teste.css';

const SearchOptions = ({ selectedOption, onOptionChange }) => {

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  // Função para definir o conteúdo da popup baseado na opção
  const handleOptionClick = (option) => {
    let content = '';

    switch (option) {
      case 'telefone':
        content = 'Esta opção permite pesquisar por dados relacionados a números de telefone. Formato: 84991748596';
        break;
      case 'cpf':
        content = 'Esta opção permite pesquisar por dados relacionados ao CPF. Formato: 12345678945';
        break;
      case 'spc':
        content = 'Esta opção retorna informações sobre pendências no SPC. Formato: 12345678945';
        break;
      // Adicione explicações para outras opções aqui
      default:
        content = 'Informações sobre esta opção.';
    }

    setPopupContent(content);
    setShowPopup(true);
  };

  // Função para fechar a popup
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="search-options-wrapper">
      <div className="option-container">
        <input
          type="radio"
          name="searchtype"
          value="telefone"
          id="telefone"
          className="radio-label-button"
          checked={selectedOption === 'telefone'}
          onChange={onOptionChange}
          onClick={() => handleOptionClick('telefone')}
        />
        <label htmlFor="telefone" className="radio-button-width">
          <i className="fa-solid fa-phone"></i> Telefone
        </label>

        <input
          type="radio"
          name="searchtype"
          value="cpf"
          id="cpf"
          className="radio-label-button"
          checked={selectedOption === 'cpf'}
          onChange={onOptionChange}
          onClick={() => handleOptionClick('cpf')}
        />
        <label htmlFor="cpf" className="radio-button-width">
          <i className="fa-solid fa-address-card"></i> CPF
        </label>

        <input
  type="radio"
  name="searchtype"
  value="cpf1"
  id="cpf1"
  className="radio-label-button"
  checked={selectedOption === 'cpf1'}
  onChange={onOptionChange}
  onClick={() => handleOptionClick('cpf1')}
/>
<label htmlFor="cpf1" className="radio-button-width"><i class="fa-solid fa-address-card"></i> CPF 1</label>

<input
  type="radio"
  name="searchtype"
  value="cpf2"
  id="cpf2"
  className="radio-label-button"
  checked={selectedOption === 'cpf2'}
  onChange={onOptionChange}
  onClick={() => handleOptionClick('cpf2')}
/>
<label htmlFor="cpf2" className="radio-button-width"><i class="fa-solid fa-address-card"></i> CPF 2</label>


        <input
          type="radio"
          name="searchtype"
          value="spc"
          id="spc"
          className="radio-label-button"
          checked={selectedOption === 'spc'}
          onChange={onOptionChange}
          onClick={() => handleOptionClick('spc')}
        />
        <label htmlFor="spc" className="radio-button-width">SPC</label>

<input
  type="radio"
  name="searchtype"
  value="spc1"
  id="spc1"
  className="radio-label-button"
  checked={selectedOption === 'spc1'}
  onChange={onOptionChange}
/>
<label htmlFor="spc1" className="radio-button-width">SPC1</label>

<input
  type="radio"
  name="searchtype"
  value="cadsus"
  id="cadsus"
  className="radio-label-button"
  checked={selectedOption === 'cadsus'}
  onChange={onOptionChange}
/>
<label htmlFor="cadsus" className="radio-button-width"><i class="fa-solid fa-star-of-life"></i> CadSus</label>

<input
  type="radio"
  name="searchtype"
  value="receitacpf"
  id="receitacpf"
  className="radio-label-button"
  checked={selectedOption === 'receitacpf'}
  onChange={onOptionChange}
/>
<label htmlFor="receitacpf" className="radio-button-width"><i class="fa fa-university" aria-hidden="true"></i> Receita CPF</label>

<input
  type="radio"
  name="searchtype"
  value="nb"
  id="nb"
  className="radio-label-button"
  checked={selectedOption === 'nb'}
  onChange={onOptionChange}
/>
<label htmlFor="nb" className="radio-button-width">NB</label>

<input
  type="radio"
  name="searchtype"
  value="cpftel"
  id="cpftel"
  className="radio-label-button"
  checked={selectedOption === 'cpftel'}
  onChange={onOptionChange}
/>
<label htmlFor="cpftel" className="radio-button-width"><i class="fa-solid fa-mobile-button"></i> CPF Tel</label>

<input
  type="radio"
  name="searchtype"
  value="cpf-mail"
  id="cpf-mail"
  className="radio-label-button"
  checked={selectedOption === 'cpf-mail'}
  onChange={onOptionChange}
/>
<label htmlFor="cpf-mail" className="radio-button-width">CPF Mail</label>

<input
  type="radio"
  name="searchtype"
  value="score"
  id="score"
  className="radio-label-button"
  checked={selectedOption === 'score'}
  onChange={onOptionChange}
/>
<label htmlFor="score" className="radio-button-width"><i class="fa-solid fa-gauge-high"></i> Score</label>

<input
  type="radio"
  name="searchtype"
  value="parente"
  id="parente"
  className="radio-label-button"
  checked={selectedOption === 'parente'}
  onChange={onOptionChange}
/>
<label htmlFor="parente" className="radio-button-width"><i class="fa-solid fa-users"></i> Parentes</label>

<input
  type="radio"
  name="searchtype"
  value="cep-full"
  id="cep-full"
  className="radio-label-button"
  checked={selectedOption === 'cep-full'}
  onChange={onOptionChange}
/>
<label htmlFor="cep-full" className="radio-button-width">CEP Full</label>

<input
  type="radio"
  name="searchtype"
  value="spc-nome"
  id="spc-nome"
  className="radio-label-button"
  checked={selectedOption === 'spc-nome'}
  onChange={onOptionChange}
/>
<label htmlFor="spc-nome" className="radio-button-width">SPC Nome</label>

<input
  type="radio"
  name="searchtype"
  value="nome"
  id="nome"
  className="radio-label-button"
  checked={selectedOption === 'nome'}
  onChange={onOptionChange}
/>
<label htmlFor="nome" className="radio-button-width">Nome</label>

<input
  type="radio"
  name="searchtype"
  value="nomes"
  id="nomes"
  className="radio-label-button"
  checked={selectedOption === 'nomes'}
  onChange={onOptionChange}
/>
<label htmlFor="nomes" className="radio-button-width">Nomes</label>

<input
  type="radio"
  name="searchtype"
  value="spc-email"
  id="spc-email"
  className="radio-label-button"
  checked={selectedOption === 'spc-email'}
  onChange={onOptionChange}
/>
<label htmlFor="spc-email" className="radio-button-width">SPC Email</label>

<input
  type="radio"
  name="searchtype"
  value="email-1"
  id="email-1"
  className="radio-label-button"
  checked={selectedOption === 'email-1'}
  onChange={onOptionChange}
/>
<label htmlFor="email-1" className="radio-button-width">Email 1</label>

<input
  type="radio"
  name="searchtype"
  value="detran"
  id="detran"
  className="radio-label-button"
  checked={selectedOption === 'detran'}
  onChange={onOptionChange}
/>
<label htmlFor="detran" className="radio-button-width"><i class="fa-solid fa-car"></i> Placa</label>

<input
  type="radio"
  name="searchtype"
  value="data"
  id="data"
  className="radio-label-button"
  checked={selectedOption === 'data'}
  onChange={onOptionChange}
/>
<label htmlFor="data" className="radio-button-width"><i class="fa-solid fa-calendar-days"></i> Data</label>

<input
  type="radio"
  name="searchtype"
  value="spc-tel"
  id="spc-tel"
  className="radio-label-button"
  checked={selectedOption === 'spc-tel'}
  onChange={onOptionChange}
/>
<label htmlFor="spc-tel" className="radio-button-width">SPC Tel</label>

<input
  type="radio"
  name="searchtype"
  value="telefone-i"
  id="telefone-i"
  className="radio-label-button"
  checked={selectedOption === 'telefone-i'}
  onChange={onOptionChange}
/>
<label htmlFor="telefone-i" className="radio-button-width">Telefone I</label>

<input
  type="radio"
  name="searchtype"
  value="movel-fixo"
  id="movel-fixo"
  className="radio-label-button"
  checked={selectedOption === 'movel-fixo'}
  onChange={onOptionChange}
/>
<label htmlFor="movel-fixo" className="radio-button-width">Movel e fixo</label>

<input
  type="radio"
  name="searchtype"
  value="rg"
  id="rg"
  className="radio-label-button"
  checked={selectedOption === 'rg'}
  onChange={onOptionChange}
/>
<label htmlFor="rg" className="radio-button-width">RG</label>

<input
  type="radio"
  name="searchtype"
  value="titulo"
  id="titulo"
  className="radio-label-button"
  checked={selectedOption === 'titulo'}
  onChange={onOptionChange}
/>
<label htmlFor="titulo" className="radio-button-width"><i class="fa-regular fa-id-card"></i> Título</label>

<input
  type="radio"
  name="searchtype"
  value="fotorj"
  id="fotorj"
  className="radio-label-button"
  checked={selectedOption === 'fotorj'}
  onChange={onOptionChange}
/>
<label htmlFor="fotorj" className="radio-button-width"><i class="fa-solid fa-image"></i> Foto RJ</label>

<input
  type="radio"
  name="searchtype"
  value="cbo"
  id="cbo"
  className="radio-label-button"
  checked={selectedOption === 'cbo'}
  onChange={onOptionChange}
/>
<label htmlFor="cbo" className="radio-button-width">CBO</label>

<input
  type="radio"
  name="searchtype"
  value="cnpjfunc"
  id="cnpjfunc"
  className="radio-label-button"
  checked={selectedOption === 'cnpjfunc'}
  onChange={onOptionChange}
/>
<label htmlFor="cnpjfunc" className="radio-button-width"><i class="fa-solid fa-briefcase"></i> Funcionario</label>

<input
  type="radio"
  name="searchtype"
  value="renda"
  id="renda"
  className="radio-label-button"
  checked={selectedOption === 'renda'}
  onChange={onOptionChange}
/>
<label htmlFor="renda" className="radio-button-width"><i class="fa-solid fa-money-check-dollar"></i> Renda</label>

      </div>

      {/* Popup */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={closePopup}>
              &times;
            </span>
            <p>{popupContent}</p>
          </div>
        </div>
      )}
    </div>
  );
};



export default SearchOptions;
