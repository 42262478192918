import React from 'react';
import '../styles/FormattedResults.css';

const formatDate = (date) => {
  if (!date) return 'N/A';
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return new Date(date).toLocaleDateString('en-US', options);
};

const formatCPFResults = (data) => {
  const dados = data.DADOS ? data.DADOS[0] : {};
  const email = data.EMAIL ? data.EMAIL.map(e => e.EMAIL).join(', ') : 'N/A';
  const historicoTelefones = data.HISTORICO_TELEFONES ? data.HISTORICO_TELEFONES.map(tel => `${tel.DDD} ${tel.TELEFONE}`).join(', ') : 'N/A';
  const endereco = data.ENDERECOS ? data.ENDERECOS[0] : {};
  const poderAquisitivo = data.PODER_AQUISITIVO ? data.PODER_AQUISITIVO[0] : {};
  const parentes = data.PARENTES ? data.PARENTES.map(p => `${p.VINCULO}: ${p.NOME_VINCULO}`).join(', ') : 'N/A';
  const pis = data.PIS && data.PIS[0] ? data.PIS[0].PIS : 'N/A';
  const universitario = data.UNIVERSITARIO ? data.UNIVERSITARIO.join(', ') : 'N/A';
  const score = data.SCORE ? data.SCORE[0] : {};
  const irpf = data.IRPF ? data.IRPF[0] : {};
  const tse = data.TSE ? data.TSE.join(', ') : 'N/A';
  const interesses = data.INTERESSES ? data.INTERESSES[0] : {};


  return (
    <div className="result-item">
      <div className="result-field"><strong>CPF:</strong> {dados.CPF || 'N/A'}</div>
      <div className="result-field"><strong>Nome:</strong> {dados.NOME || 'N/A'}</div>
      <div className="result-field"><strong>Sexo:</strong> {dados.SEXO || 'N/A'}</div>
      <div className="result-field"><strong>Data de Nascimento:</strong> {dados.NASC || 'N/A'}</div>
      <div className="result-field"><strong>Nome da Mãe:</strong> {dados.NOME_MAE || 'N/A'}</div>
      <div className="result-field"><strong>Nome do Pai:</strong> {dados.NOME_PAI || 'N/A'}</div>
      <div className="result-field"><strong>Renda:</strong> {dados.RENDA || 'N/A'}</div>
      <div className="result-field"><strong>E-mail:</strong> {email}</div>
      <div className="result-field"><strong>Telefones:</strong> {historicoTelefones}</div>
      <div className="result-field"><strong>Endereço:</strong> {`${endereco.LOGR_TIPO || ''} ${endereco.LOGR_NOME || ''}, ${endereco.LOGR_NUMERO || ''}, ${endereco.BAIRRO || ''}, ${endereco.CIDADE || ''}, ${endereco.UF || ''}, CEP: ${endereco.CEP || 'N/A'}`}</div>
      <div className="result-field"><strong>Poder Aquisitivo:</strong> {poderAquisitivo.PODER_AQUISITIVO || 'N/A'}</div>
      <div className="result-field"><strong>Parentes:</strong> {parentes}</div>
      <div className="result-field"><strong>PIS:</strong> {pis}</div>
      <div className="result-field"><strong>Universitário:</strong> {universitario}</div>
      <div className="result-field"><strong>Score:</strong> {score.CSB8 || 'N/A'}</div>
      <div className="result-field"><strong>IRPF:</strong> {irpf.Sit_Receita_Federal || 'N/A'}</div>
      <div className="result-field"><strong>TSE:</strong> {tse}</div>
      <div className="result-field"><strong>Interesses:</strong> {Object.keys(interesses).map(key => `${key}: ${interesses[key]}`).join(', ')}</div>
    </div>
  );
};

const formatCPF1Results = (data) => {
  const resultado = data.resultado || {};

  const dado1 = resultado["Dado 1"] ? resultado["Dado 1"][0] : {};
  const dado2 = resultado["Dado 2"] ? resultado["Dado 2"][0] : {};
  const dado3 = resultado["Dado 3"] ? resultado["Dado 3"][0] : {};
  const dado4 = resultado["Dado 4"] ? resultado["Dado 4"][0] : {};
  const dado5 = resultado["Dado 5"] ? resultado["Dado 5"][0] : {};
  const dado6 = resultado["Dado 6"] ? resultado["Dado 6"][0] : {};
  const dado7 = resultado["Dado 7"] ? resultado["Dado 7"][0] : {};
  const dado8 = resultado["Dado 8"] ? resultado["Dado 8"][0] : {};
  const dado9 = resultado["Dado 9"] ? resultado["Dado 9"][0] : {};

  return (
    <div className="result-item">
      <div className="result-field"><strong>CPF:</strong> {dado1.cpf || 'N/A'}</div>
      <div className="result-field"><strong>Nome:</strong> {dado1.nome || dado2.NOME || dado3.nome || dado4.nome || dado5.Nome || dado6.nome || dado7.nome || dado9.Nome || 'N/A'}</div>
      <div className="result-field"><strong>Sexo:</strong> {dado1.sexo || dado7.sexo || dado9.SEXO || 'N/A'}</div>
      <div className="result-field"><strong>Data de Nascimento:</strong> {dado1.dataNascimento || dado3.nascimento || dado5.DataNascimento || dado7.data_nascimento || dado9.DataNasc || 'N/A'}</div>
      <div className="result-field"><strong>Nome da Mãe:</strong> {dado1.nomeMae || dado4.mae || dado5.NomeMae || dado9.NOME_MAE || 'N/A'}</div>
      <div className="result-field"><strong>Nome do Pai:</strong> {dado4.pai || 'N/A'}</div>
      <div className="result-field"><strong>Ocupação:</strong> {dado1.idNaturezaOcupacao || 'N/A'}</div>
      <div className="result-field"><strong>Ocupação Principal:</strong> {dado1.idOcupacaoPrincipal || 'N/A'}</div>
      <div className="result-field"><strong>Unidade Administrativa:</strong> {dado1.idUnidadeAdministrativa || 'N/A'}</div>
      <div className="result-field"><strong>Situação Estrangeiro:</strong> {dado1.situacaoEstrangeiro || 'N/A'}</div>
      <div className="result-field"><strong>Situação Residente Exteior:</strong> {dado1.situacaoResidenteExterior || 'N/A'}</div>
      <div className="result-field"><strong>Titulo de Eleitor:</strong> {dado1.numeroTituloEleitor || 'N/A'}</div>
      <div className="result-field"><strong>Ano de Obito:</strong> {dado1.anoObito || 'N/A'}</div>
      <div className="result-field"><strong>Municipio IBGE:</strong> {dado1.idMunicipioIbge || 'N/A'}</div>
      <div className="result-field"><strong>Complemento:</strong> {dado1.descricaoComplemento || 'N/A'}</div>
      <div className="result-field"><strong>Data de Atualização:</strong> {dado1.dataAtualizacaoRfb || 'N/A'}</div>
      <div className="result-field"><strong>Data de Processamento:</strong> {dado1.dataProcessamento || 'N/A'}</div>
      <div className="result-field"><strong>Renda:</strong> {dado8.FaixaRenda || dado9.RendaOriginal || 'N/A'}</div>
      <div className="result-field"><strong>E-mail:</strong> {dado3.email || dado9.Email1 || 'N/A'}</div>
      <div className="result-field"><strong>Telefones:</strong> {`${dado1.ddd || dado2.DDD || dado6.dddTelefone || ''} ${dado1.telefone || dado2.TELEFONE || dado6.numeroTelefone || dado9.Telefone1 || ''}`}</div>
      <div className="result-field"><strong>Endereço:</strong> {`${dado1.nomeLogradouro || dado2.ENDERECO || dado4.enderecoLogr || dado5.EnderecoLogradouro || dado6.enderecoLogradouro || dado9.Logradouto || ''}, ${dado1.numeroLogradouro || dado2.NUMERO || dado4.enderecoNu || dado5.EnderecoNumero || dado6.enderecoNumero || dado9.Numero || ''}, ${dado1.nomeBairro || dado2.BAIRRO || dado4.enderecoBa || dado5.EnderecoBairro || dado6.enderecoBairro || dado9.Bairro || ''}, ${dado1.nomeMunicipio || dado2.CIDADE || dado4.enderecoMuni || dado5.EnderecoNmMunicipio || dado6.enderecoCidade || dado9.Cidade || ''}, ${dado1.siglaUf || dado2.UF || dado5.EnderecoUf || dado6.enderecoEstado || dado9.UF || ''}, CEP: ${dado1.numeroCep || dado2.CEP || dado4.enderecoCe || dado5.EnderecoCep || dado6.enderecoCep || dado9.CEP || 'N/A'}`}</div>
      <div className="result-field"><strong>Situação:</strong> {dado8.Situacao || 'N/A'}</div>
    </div>
  );
};

const formatRendaResults = (data) => {
  const resultado = data.resultado ? data.resultado[0] : {};

  return (
    <div className="result-item">
      <div className="result-field"><strong>IDPF:</strong> {resultado.IDPF}</div>
      <div className="result-field"><strong>CPF:</strong> {resultado.CPF}</div>
      <div className="result-field"><strong>Nome:</strong> {resultado.Nome}</div>
      <div className="result-field"><strong>Sexo:</strong> {resultado.SEXO}</div>
      <div className="result-field"><strong>Nome da Mãe:</strong> {resultado.NOME_MAE}</div>
      <div className="result-field"><strong>Renda Original:</strong> {resultado.RendaOriginal}</div>
      <div className="result-field"><strong>Data de Nascimento:</strong> {resultado.DataNasc}</div>
      <div className="result-field"><strong>Idade:</strong> {resultado.Idade}</div>
      <div className="result-field"><strong>Endereço:</strong> {`${resultado.TipoLog} ${resultado.Logradouto}, ${resultado.Numero}, ${resultado.Compl}, ${resultado.Bairro}, ${resultado.Cidade}, ${resultado.UF}, CEP: ${resultado.CEP}`}</div>
      <div className="result-field"><strong>Telefones:</strong> {`${resultado.Telefone1}, ${resultado.Telefone4}, ${resultado.Telefone5}`}</div>
      <div className="result-field"><strong>Email:</strong> {resultado.Email1}</div>
    </div>
  );
};

const formatCadSusResults = (data) => {
  const resultado = data || {}; // Garante que `data` seja um objeto vazio se não houver dados

  // Função auxiliar para tratar valores nulos ou \\N
  const formatValue = (value) => (value && value !== '\\N' ? value : 'N/A');

  return (
    <div className="result-item">
      <div className="result-field"><strong>ID:</strong> {formatValue(resultado.id)}</div>
      <div className="result-field"><strong>CPF:</strong> {formatValue(resultado.cpf)}</div>
      <div className="result-field"><strong>RG:</strong> {formatValue(resultado.rg)}</div>
      <div className="result-field"><strong>Nome:</strong> {formatValue(resultado.nome)}</div>
      <div className="result-field"><strong>Data de Nascimento:</strong> {formatValue(resultado.data_nascimento)}</div>
      <div className="result-field"><strong>CNS:</strong> {formatValue(resultado.cns)}</div>
      <div className="result-field"><strong>Mãe:</strong> {formatValue(resultado.nomeMae)}</div>
      <div className="result-field"><strong>Pai:</strong> {formatValue(resultado.nomePai)}</div>
      <div className="result-field"><strong>Sexo:</strong> {formatValue(resultado.sexo)}</div>
      <div className="result-field"><strong>Cor:</strong> {formatValue(resultado.racaCorDescricao)}</div>
      <div className="result-field"><strong>Nacionalidade:</strong> {formatValue(resultado.nacionalidade)}</div>
      <div className="result-field"><strong>País:</strong> {formatValue(resultado.paisNascimento)}</div>
      <div className="result-field"><strong>Município de Nascimento:</strong> {formatValue(resultado.municipioNascimento)}</div>
      <div className="result-field"><strong>Município:</strong> {formatValue(resultado.municipioNascimentoSemUF)}</div>
    </div>
  );
};


const formatCPFTelResults = (data) => {
  const resultado = data.DADOS ? data.DADOS[0] : {};
  const telefones = data.HISTORICO_TELEFONES ? data.HISTORICO_TELEFONES.map(tel => `${tel.DDD} ${tel.TELEFONE}`).join(', ') : 'N/A';

  return (
    <div className="result-item">
      <div className="result-field"><strong>CPF:</strong> {resultado.CPF || 'N/A'}</div>
      <div className="result-field"><strong>Nome:</strong> {resultado.NOME || 'N/A'}</div>
      <div className="result-field"><strong>Telefones:</strong> {telefones}</div>
    </div>
  );
};


const formatCPF2Results = (data) => {
  const resultado = data.resultado ? data.resultado[0].tabela1[0] : {}; // Corrigido para acessar tabela1

  return (
    <div className="result-item">
      <div className="result-field"><strong>CPF:</strong> {resultado.Cpf || 'N/A'}</div>
      <div className="result-field"><strong>Nome:</strong> {resultado.Nome || 'N/A'}</div>
      <div className="result-field"><strong>Nome da Mãe:</strong> {resultado.NomeMae || 'N/A'}</div>
      <div className="result-field"><strong>Data de Nascimento:</strong> {resultado.DataNascimento || 'N/A'}</div>
      <div className="result-field"><strong>Endereço:</strong> {`Rua: ${resultado.EnderecoNmMunicipio || 'N/A'}, Bairro: ${resultado.EnderecoBairro || 'N/A'}, Estado: ${resultado.EnderecoUf || 'N/A'}, CEP: ${resultado.EnderecoCep || 'N/A'}`}</div>
      <div className="result-field"><strong>Telefones:</strong> {resultado.Telefones || 'N/A'}</div>
      <div className="result-field"><strong>Email:</strong> {resultado.Email1 || 'N/A'}</div>
    </div>
  );
};

const formatReceitaCPFResults = (data) => {
  return (
    <div className="result-item">
      <div className="result-field"><strong>ID:</strong> {data.id}</div>
      <div className="result-field"><strong>CPF:</strong> {data.cpf}</div>
      <div className="result-field"><strong>Nome:</strong> {data.nome}</div>
      <div className="result-field"><strong>Primeiro Nome:</strong> {data.primeiroNome}</div>
      <div className="result-field"><strong>Nome do Meio:</strong> {data.nomeMeio}</div>
      <div className="result-field"><strong>Ultimo Nome:</strong> {data.ultimoNome}</div>
      <div className="result-field"><strong>Sobre nome Mãe:</strong> {data.sobrenomeMae}</div>
      <div className="result-field"><strong>Data de Inclusão:</strong> {data.dataInclusao}</div>
      <div className="result-field"><strong>Data de Atualização:</strong> {data.dataAtualizacao}</div>
      <div className="result-field"><strong>Rua:</strong> {data.enderecoLogradouro}</div>
      <div className="result-field"><strong>Nº:</strong> {data.enderecoNumero}</div>
      <div className="result-field"><strong>Complemento:</strong> {data.enderecoComplemento}</div>
      <div className="result-field"><strong>Bairro:</strong> {data.enderecoBairro}</div>
      <div className="result-field"><strong>Cidade:</strong> {data.enderecoCidade}</div>
      <div className="result-field"><strong>Estado:</strong> {data.enderecoEstado}</div>
      <div className="result-field"><strong>Cep:</strong> {data.enderecoCep}</div>
      <div className="result-field"><strong>IBGE:</strong> {data.codCidadeIBGE}</div>
      <div className="result-field"><strong>DDD:</strong> {data.dddTelefone}</div>
      <div className="result-field"><strong>Telefone:</strong> {data.numeroTelefone}</div>
      <div className="result-field"><strong>Meso Região:</strong> {data.mesoregiao}</div>
      <div className="result-field"><strong>Micro Região:</strong> {data.microregiao}</div>
      <div className="result-field"><strong>Municipio:</strong> {data.municipio}</div>
      <div className="result-field"><strong>Codigo de Região:</strong> {data.regiaoCod}</div>
      <div className="result-field"><strong>SubCodigo:</strong> {data.subRegiaoCod}</div>
      <div className="result-field"><strong>Distrito:</strong> {data.distritoCod}</div>
      <div className="result-field"><strong>SubDistrito:</strong> {data.subDistritoCod}</div>
    </div>
  );
};

const formatCNPJResults = (data) => {
  return (
    <div className="result-item">
      <div className="result-field"><strong>CNPJ:</strong> {data.cnpj}</div>
      <div className="result-field"><strong>Opening Date:</strong> {data.abertura}</div>
      <div className="result-field"><strong>Status:</strong> {data.situacao}</div>
      <div className="result-field"><strong>Type:</strong> {data.tipo}</div>
      <div className="result-field"><strong>Name:</strong> {data.nome}</div>
      <div className="result-field"><strong>Company Size:</strong> {data.porte}</div>
      <div className="result-field"><strong>Legal Nature:</strong> {data.natureza_juridica}</div>
      <div className="result-field"><strong>Main Activity:</strong> {data.atividade_principal.map(activity => `${activity.code} - ${activity.text}`).join(', ')}</div>
      <div className="result-field"><strong>Secondary Activities:</strong> {data.atividades_secundarias.map(activity => `${activity.code} - ${activity.text}`).join(', ')}</div>
      <div className="result-field"><strong>Address:</strong> {data.logradouro}, {data.numero}, {data.complemento}, {data.bairro}, {data.municipio} - {data.uf}, {data.cep}</div>
      <div className="result-field"><strong>Email:</strong> {data.email}</div>
      <div className="result-field"><strong>Phone:</strong> {data.telefone}</div>
    </div>
  );
};

const formatScoreResults = (data) => {
  return (
    <div className="result-item">
      <div className="result-field"><strong>CPF:</strong> {data.CPF}</div>
      <div className="result-field"><strong>CSBA:</strong> {data.CSBA}</div>
      <div className="result-field"><strong>CSBA_FAIXA:</strong> {data.CSBA_FAIXA}</div>
      <div className="result-field"><strong>COD_PODER_AQUISITIVO:</strong> {data.COD_PODER_AQUISITIVO}</div>
      <div className="result-field"><strong>PODER_AQUISITIVO:</strong> {data.PODER_AQUISITIVO}</div>
      <div className="result-field"><strong>RENDA_PODER_AQUISITIVO:</strong> {data.RENDA_PODER_AQUISITIVO}</div>
      <div className="result-field"><strong>FX_PODER_AQUISITIVO:</strong> {data.FX_PODER_AQUISITIVO}</div>
      <div className="result-field"><strong>RENDA:</strong> {data.RENDA}</div>
      
    </div>
  );
};

const formatParenteResults = (data) => {
  return (
    <div className="result-list">
      {data.map((parente, index) => (
        <div key={index} className="result-item">
          <div className="result-field"><strong>CPF:</strong> {parente.CPF_Completo}</div>
          <div className="result-field"><strong>NOME:</strong> {parente.NOME}</div>
          <div className="result-field"><strong>CPF VINCULO:</strong> {parente.CPF_VINCULO}</div>
          <div className="result-field"><strong>NOME VINCULO:</strong> {parente.NOME_VINCULO}</div>
          <div className="result-field"><strong>VINCULO:</strong> {parente.VINCULO}</div>
        </div>
      ))}
    </div>
  );
};


const formatPlacaResults = (data) => {
  //const resultado = data.resultado ? data.resultado[0] : {};
  const resultado = data.resultado && data.resultado.length ? data.resultado[0] : {};

  const credauto = resultado["credauto.db"] ? resultado["credauto.db"][0] : {};

  return (
    <div className="result-item">
      <div className="result-field"><strong>ID TB BIN:</strong> {credauto.ID_TB_BIN || 'N/A'}</div>
      <div className="result-field"><strong>Placa:</strong> {credauto.PLACA || 'N/A'}</div>
      <div className="result-field"><strong>Chassi:</strong> {credauto.CHASSI || 'N/A'}</div>
      <div className="result-field"><strong>Renavam:</strong> {credauto.RENAVAM || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Marca Modelo:</strong> {credauto.COD_MARCA_MODELO || 'N/A'}</div>
      <div className="result-field"><strong>Ano Fabricação:</strong> {credauto.ANO_FABRICACAO || 'N/A'}</div>
      <div className="result-field"><strong>Ano Modelo:</strong> {credauto.ANO_MODELO || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Combustível:</strong> {credauto.COD_COMBUST || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Tipo Veículo:</strong> {credauto.COD_TIPO_VEIC || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Espécie Veículo:</strong> {credauto.COD_ESP_VEIC || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Cor:</strong> {credauto.COD_COR || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Tipo Carroceria:</strong> {credauto.COD_TIPO_CARROCERIA || 'N/A'}</div>
      <div className="result-field"><strong>Nacionalidade:</strong> {credauto.NACIONALIDADE || 'N/A'}</div>
      <div className="result-field"><strong>Num. Motor:</strong> {credauto.NUM_MOTOR || 'N/A'}</div>
      <div className="result-field"><strong>Potência:</strong> {credauto.POTENCIA || 'N/A'}</div>
      <div className="result-field"><strong>Carga:</strong> {credauto.CARGA || 'N/A'}</div>
      <div className="result-field"><strong>Num. Carroceria:</strong> {credauto.NUM_CARROCERIA || 'N/A'}</div>
      <div className="result-field"><strong>Num. Caixa Câmbio:</strong> {credauto.NUM_CAIXA_CAMBIO || 'N/A'}</div>
      <div className="result-field"><strong>Num. Eixo Traseiro:</strong> {credauto.NUM_EIXO_TRAS || 'N/A'}</div>
      <div className="result-field"><strong>Num. Terceiro Eixo:</strong> {credauto.NUM_TERC_EIXO || 'N/A'}</div>
      <div className="result-field"><strong>Num. Eixos:</strong> {credauto.NUM_EIXOS || 'N/A'}</div>
      <div className="result-field"><strong>Cilindradas:</strong> {credauto.CILINDRADAS || 'N/A'}</div>
      <div className="result-field"><strong>Cap. Max. Tração:</strong> {credauto.CAP_MAX_TRACAO || 'N/A'}</div>
      <div className="result-field"><strong>Peso Bruto Total:</strong> {credauto.PESO_BRUTO_TOTAL || 'N/A'}</div>
      <div className="result-field"><strong>Lugares:</strong> {credauto.LUGARES || 'N/A'}</div>
      <div className="result-field"><strong>Tipo Montagem:</strong> {credauto.TIPO_MONTAGEM || 'N/A'}</div>
      <div className="result-field"><strong>UF Jurisdição:</strong> {credauto.UF_JURISDICAO || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Município:</strong> {credauto.CODMUN || 'N/A'}</div>
      <div className="result-field"><strong>Data Emplacamento:</strong> {credauto.DT_EMPLACAMENTO || 'N/A'}</div>
      <div className="result-field"><strong>Doc. Faturado:</strong> {credauto.DOC_FATURADO || 'N/A'}</div>
      <div className="result-field"><strong>Tipo Doc. Imp.:</strong> {credauto.TIPO_DOC_IMP || 'N/A'}</div>
      <div className="result-field"><strong>Num. Ident. Imp.:</strong> {credauto.NUM_IDENT_IMP || 'N/A'}</div>
      <div className="result-field"><strong>Num. Doc. Imp.:</strong> {credauto.NUM_DOC_IMP || 'N/A'}</div>
      <div className="result-field"><strong>Data Reg. DI:</strong> {credauto.DATA_REG_DI || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Unid. SRF:</strong> {credauto.COD_UNID_SRF || 'N/A'}</div>
      <div className="result-field"><strong>Data Ult. Atualização:</strong> {credauto.DAT_ULT_ATU || 'N/A'}</div>
      <div className="result-field"><strong>Restrição 1:</strong> {credauto.RESTR_01 || 'N/A'}</div>
      <div className="result-field"><strong>Restrição 2:</strong> {credauto.RESTR_02 || 'N/A'}</div>
      <div className="result-field"><strong>Restrição 3:</strong> {credauto.RESTR_03 || 'N/A'}</div>
      <div className="result-field"><strong>Restrição 4:</strong> {credauto.RESTR_04 || 'N/A'}</div>
      <div className="result-field"><strong>Data Limite Restrição:</strong> {credauto.DATA_LIM_RESTR || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Sit. Veículo:</strong> {credauto.COD_SIT_VEIC || 'N/A'}</div>
      <div className="result-field"><strong>Cod. Sit. Chassi:</strong> {credauto.COD_SIT_CHASSI || 'N/A'}</div>
      <div className="result-field"><strong>Tipo Doc. Faturado:</strong> {credauto.TIPO_DOC_FATURADO || 'N/A'}</div>
      <div className="result-field"><strong>UF Faturado:</strong> {credauto.UF_FATURADO || 'N/A'}</div>
      <div className="result-field"><strong>Procedência:</strong> {credauto.PROCEDENCIA || 'N/A'}</div>
      <div className="result-field"><strong>Tipo Doc. Proprietário:</strong> {credauto.TIPO_DOC_PROPRIETARIO || 'N/A'}</div>
      <div className="result-field"><strong>Data Insr.:</strong> {credauto.DT_INSR || 'N/A'}</div>
      <div className="result-field"><strong>Co. User Insr.:</strong> {credauto.CO_USER_INSR || 'N/A'}</div>
      <div className="result-field"><strong>Data Last Updt:</strong> {credauto.DT_LAST_UPDT || 'N/A'}</div>
      <div className="result-field"><strong>Co. User Last Updt:</strong> {credauto.CO_USER_LAST_UPDT || 'N/A'}</div>
    </div>
  );
};


const formatFotoResults = (data) => {
  return (
    <div className="result-list">
      {data.map((foto, index) => (
        <div key={index} className="result-item">
          <div className="result-field"><strong>CPF:</strong> {foto.cpf}</div>
          <div className="result-field"><strong>Nome:</strong> {foto.nome1}</div>
          <div className="result-field"><strong>Data de Nascimento:</strong> {foto.dtnasc}</div>
          <div className="result-field"><strong>Nome da Mãe:</strong> {foto.mae}</div>
          <div className="result-field"><strong>RG:</strong> {foto.rg}</div>
          <div className="result-field">
            <strong>Foto:</strong><br></br><br></br>
            {foto.foto ? (
             <center> <img 
                src={`data:image/jpeg;base64,${foto.foto}`} 
                alt="Foto do usuário" 
                style={{ width: '250px' }}
              /></center>
            ) : (
              <span>Foto não disponível</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};





const FormattedResults = ({ results }) => {
  if (!results) return null;

  return (
    <div className="formatted-results">
      {Object.entries(results).map(([source, entries]) => (
        <div key={source} className="results-section">
          <h3>{source}</h3>
          {entries.map((entry, index) => (
            <div key={index} className="result-item">
              {source === 'CPF API' && formatCPFResults(entry)}
              {source === 'CNPJ API' && formatCNPJResults(entry)}
              {source === 'RENDA API' && formatRendaResults(entry)}
              {source === 'DETRAN API' && formatPlacaResults(entry)}
              {source === 'CPF2 API' && formatCPF2Results(entry)}
              {source === 'SCORE API' && formatScoreResults(entry)}
              {source === 'PARENTE API' && formatParenteResults(entry)}
              {source === 'FOTORJ API' && formatFotoResults(entry)}
              {source === 'CPF1 API' && formatCPF1Results(entry)}
              {source === 'CPFTEL API' && formatCPFTelResults(entry)}
              {source === 'CADSUS API' && formatCadSusResults(entry)}
              {source === 'RECEITACPF API' && formatReceitaCPFResults(entry)}

              
              
              
              

              {source !== 'CPF API' && source !== 'CADSUS API' &&  source !== 'RECEITACPF API' &&  source !== 'CPF1 API' && source !== 'CPFTEL API' && source !== 'CPF2 API' && source !== 'RENDA API' && source !== 'DETRAN API' &&  source !== 'PARENTE API' && source !== 'FOTORJ API' && (
                <div>
                  {Object.entries(entry).map(([key, value]) => (
                    <div key={key} className="result-field">
                      <strong>{key.replace(/_/g, ' ')}:</strong> 
                      {/* Verifica se o valor é um objeto */}
                      {typeof value === 'object' && !Array.isArray(value) ? (
                        <pre>{JSON.stringify(value, null, 2)}</pre> // Converte o objeto para string JSON
                      ) : (
                        Array.isArray(value) ? value.join(', ') : value // Caso seja um array, junta os valores, senão renderiza normalmente
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};


export default FormattedResults;
