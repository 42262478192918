// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/FormattedResults.css */

.formatted-results {
    margin: 10px;
    padding: 1px;
    background: #f9f9f9;
    border-radius: 8px;
    text-align: left; /* Alinha o texto à esquerda */
  }
  
  .results-section {
    margin-bottom: 20px;
  }
  
  .result-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    word-break: break-word; /* Quebra palavras longas */
    overflow-wrap: break-word; /* Quebra palavras longas e mantém o layout */
    white-space: normal; /* Quebra linhas automaticamente */
  }
  
  .result-field {
    margin-bottom: 5px;
  }
  
  .result-field strong {
    color: #333;
  }
  h3{
    word-break: break-word; /* Quebra palavras longas */
    overflow-wrap: break-word; /* Quebra palavras longas e mantém o layout */
    white-space: normal; /* Quebra linhas automaticamente */
  }`, "",{"version":3,"sources":["webpack://./src/styles/FormattedResults.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB,EAAE,8BAA8B;EAClD;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB,EAAE,2BAA2B;IACnD,yBAAyB,EAAE,6CAA6C;IACxE,mBAAmB,EAAE,kCAAkC;EACzD;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;EACb;EACA;IACE,sBAAsB,EAAE,2BAA2B;IACnD,yBAAyB,EAAE,6CAA6C;IACxE,mBAAmB,EAAE,kCAAkC;EACzD","sourcesContent":["/* src/styles/FormattedResults.css */\n\n.formatted-results {\n    margin: 10px;\n    padding: 1px;\n    background: #f9f9f9;\n    border-radius: 8px;\n    text-align: left; /* Alinha o texto à esquerda */\n  }\n  \n  .results-section {\n    margin-bottom: 20px;\n  }\n  \n  .result-item {\n    margin-bottom: 10px;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    background: #fff;\n    word-break: break-word; /* Quebra palavras longas */\n    overflow-wrap: break-word; /* Quebra palavras longas e mantém o layout */\n    white-space: normal; /* Quebra linhas automaticamente */\n  }\n  \n  .result-field {\n    margin-bottom: 5px;\n  }\n  \n  .result-field strong {\n    color: #333;\n  }\n  h3{\n    word-break: break-word; /* Quebra palavras longas */\n    overflow-wrap: break-word; /* Quebra palavras longas e mantém o layout */\n    white-space: normal; /* Quebra linhas automaticamente */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
