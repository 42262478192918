// src/components/Loader.js
import React from 'react';
import '../styles/main.css'; // Certifique-se de que o CSS está sendo importado
import ParticlesBackground from './ParticlesBackground'; // Importar o componente corretamente

const Loader = () => {
  return (
    <div>
    <ParticlesBackground />

    <div className="loader-container">

      <div className="loader"></div>
    </div>
    </div>
  );
};

export default Loader;
